<template>
    <div
        class="avatar"
        :class="[
            size ? `avatar-${size}` : '',
            `user-${getUserColor(data.id)}`,
        ]"
    >
        <base-img
            v-if="data.img"
            class="img-responsive img-responsive-2by1"
            :src="data.img"
        />
        <template v-else>
            {{ data.nameInitials }}
        </template>
    </div>
</template>

<script>
import { getUserColor } from '../utils';

export default {
    props: {
        data: {
            type: Object,
            required: true,
        },
        size: {
            type: String,
            default: '',
        },
    },

    methods: {
        getUserColor,
    },
};
</script>

<style lang="scss">
@import "./../scss/05-components/base-avatar";
</style>
