var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "avatar",
      class: [
        _vm.size ? `avatar-${_vm.size}` : "",
        `user-${_vm.getUserColor(_vm.data.id)}`,
      ],
    },
    [
      _vm.data.img
        ? _c("base-img", {
            staticClass: "img-responsive img-responsive-2by1",
            attrs: { src: _vm.data.img },
          })
        : [_vm._v("\n        " + _vm._s(_vm.data.nameInitials) + "\n    ")],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }